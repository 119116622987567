export default {
    app: {
        name: "Viblica",
        version: '0.0.1',
        contact:{
            email:"contact@viblica.com",
            phone:""
        },
        api: {
            baseUrl: "https://api.viblica.com/v1/admin/",
            socket : "https://api.viblica.com/",
            logger : "https://webhook.site/89cc27b4-4385-4224-a5d8-a6f91d8236dd",
            streamingServer : {
                webcamTranscoder: "wss://mediaserver.viblica.com/webcamproxy/", //server that turn webcam data to rtmp
                http: "https://mediaserver.viblica.com/",
                https: "https://mediaserver.viblica.com/",
                websocket: "wss://mediaserver.viblica.com",
                rtmp: "rtmp://mediaserver.viblica.com/",
                apps:{
                    live:'live'
                }
            },

    }

}
}