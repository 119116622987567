import React from 'react'
import { Link, Route } from 'react-router-dom';
import { Menu, Icon, } from "antd";
const { SubMenu } = Menu;



// export const  socketio = SocketIOClient(environments.app.api.socket);

export const navigateTo = (path) => {
    window.location.replace(path)
}

export const snakecase = (text) => {
    return text.toLowerCase().trim().replace(" ", "_").replace(/\s/g, "")
  }
export const sanitizeLabel = (text) => {
    return text.toLowerCase().trim().split("_").join(" ")
  }


export const buildMenuItems = (items,parent='') => {

    return items.map(item => {
        if (!item.children || item.children.length < 1) {
            return (
                <Menu.Item key={item.name}>
                    <Link to={ parent !== '' ? `${parent.path}${item.path}` : item.path}>
                        <Icon type={item.icon} />
                        <span>
                            {item.name}
                        </span>
                    </Link>
                </Menu.Item>
            )
        } else {
    
            return (
                
                <SubMenu
                    key={item.name}
                    title={
                        <span>
                            <Icon type={item.icon} />
                            <span> {item.name}</span>
                        </span>
                    }
                >
                    {buildMenuItems(item.children,item)}

                </SubMenu>
            )
        }

    })
}

export const buildRoutesItems = (items,parent='') => {

    return items.map(item => {

        if (!item.children || item.children.length < 1) {
            return (
                <Route name={item.name} exact={item.exact} key={item.name} path={ parent ? `${parent.path}${item.path}` : item.path}>
                {  item.component}
                </Route>

            )
        } else {
    
            return (
            
                    buildRoutesItems(item.children,item)
            )
        }

    })
}


export const  prepareURL = (url) => {
    //@todo Implement a sanitizer
    // url =  new URL(url)
    return url;
}

/**
 * 
 */
export const authToken =  ()=>{
    return  localStorage.getItem("token")
}


export const sanitizePhoneNumber= (string) =>{
    return parseInt(string)
}


export const abbreviator = (text)=>{
    const splits = text.split("",text);
    const initials = splits.map((word)=> word.substring(0,1));
    return initials.toString();
}
