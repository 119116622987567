import React, { useState, useEffect, useContext } from 'react';
import {  Skeleton } from "antd";
import environment from "../../environments";
import Plot from 'react-plotly.js';
import socketClient  from 'socket.io-client';
import Webservice from '../../classes/WebService';
import GlobalStateContext from '../../context';
import { Divider } from '@material-ui/core';

const styles = {
    container: { display: 'flex', justifyContent: 'space-evenly' },
    dashboardItem: {
        textAlign: 'center',
        margin: ' 0px 10px',
        padding: '5px 15px',
    },
    title: { fontSize: '1.2rem', color: 'rgba(0, 0, 0, 0.45)' },
    statsBody: { display: 'flex', textAlign: 'center' , justifyContent:"center"},
    slash: { fontSize: ' 1.5rem', margin: '5px' },
    figure: { fontSize: ' 2rem', margin: '5px' },
}

const io = socketClient(environment.app.api.socket)



const DashboardStats = (props) => {
    const { data } = props
    return <div className="row" style={styles.container}>
        <div className="col-md-2" style={styles.dashboardItem}>
            <div className="title" style={styles.title}> Clients</div>
            <div style={styles.statsBody}>
                <div> <span style={styles.figure}> {data.clients.active.length} </span> <br />Active </div>
                <span style={styles.slash}> / </span>
                <div> <span style={styles.figure}> {data.clients.all.length} </span> <br /> Total</div>
            </div>
        </div>
        <div className="col-md-2" style={styles.dashboardItem}>
            <div className="title" style={styles.title}> Streams </div>
            <div style={styles.statsBody}>
                <div> <span style={styles.figure}> {data.streams.active.length} </span> <br />Active </div>
                <span style={styles.slash}> / </span>
                <div> <span style={styles.figure}> {data.streams.all.length} </span> <br /> Total</div>
            </div>
        </div>
        <div className="col-md-2" style={styles.dashboardItem}>
            <div className="title" style={styles.title}> Packages </div>
            <div style={styles.statsBody}>
                <div>
                    <span style={styles.figure}>
                        {data.packages.all.length}
                    </span> <br />Active
                    </div>
            </div>
        </div>
        <div className="col-md-2" style={styles.dashboardItem}>
            <div className="title" style={styles.title}> Revenue </div>
            <div style={styles.statsBody}>
                <div>
                    <span style={styles.figure}>
                        $ {data.revenue.total  ||  0  }
                    </span> <br /> Comfirmed
                    </div>
            </div>
        </div>
    </div>
}

export default function Dashboard() {
    const [loading, setLoading] = useState(false)
    const [iState, setIState] = useState(false)
    const [indicator, setIndicator] = useState()
    const webservice = new Webservice();
    const [state,dispatch] = useContext(GlobalStateContext)


    const cleanup = ()=>{
        ///
        io.removeAllListeners(`dashboard_indicator`);
    }
    const fetchData = () => {
        setLoading(true);
       
        webservice.get({ endpoint: 'dashboard' })
            .then(res => {
                setLoading(true);
                console.log(res.data)
                setIState(res.data)
            }).finally(() => setLoading(false))

    }


    useEffect(() => {
      
        io.on('dashboard_indicator', data => {
            setIndicator(data)
        })
        return cleanup;
    }, []);
    useEffect(() => {
        fetchData();
    }, []);


    const  memUsageVal =  indicator ?  (indicator.memory.used/indicator.memory.total ) * 100 : 0 ;
    const  usedCpuVal =  indicator ?  indicator.cpu.used : 0;

    return (
        <div>
            <Skeleton loading={loading} active={loading}>
                {iState ?

                    <DashboardStats data={iState} />

                    : null
                }
                <Divider/>


                <div style={{display:'flex',justifyContent:'space-evenly'}} >
                <Plot
                    data={[
                        {
                          domain: { x: [0, 1], y: [0, 1] },
                          value: memUsageVal,
                          title: { text: "Memory Usage (%)" },
                          type: "indicator",
                          mode: "gauge+number+delta",
                        
                          gauge: {
                            axis: { range: [null, 100] },
                            steps: [
                              { range: [0, 60], color: "lightgreen" },
                              { range: [60, 80], color: "lightyellow" },
                              { range: [80,100], color: "lightred" }
                            ],
                            threshold: {
                              line: { color: "red", width: 4 },
                              thickness: 0.75,
                              value: memUsageVal
                            }
                          }
                        }
                      ]}
                    layout={{  height: 340 }}
                />
                <Plot
                    data={[
                        {
                          domain: { x: [0, 1], y: [0, 1] },
                          value: usedCpuVal,
                          title: { text: "CPU Usage (%)" },
                          type: "indicator",
                          mode: "gauge+number+delta",
                        
                          gauge: {
                            axis: { range: [null, 100] },
                            steps: [
                              { range: [0, 60], color: "lightgreen" },
                              { range: [60, 80], color: "lightyellow" },
                              { range: [80,100], color: "lightred" }
                            ],
                            threshold: {
                              line: { color: "red", width: 4 },
                              thickness: 0.75,
                              value: usedCpuVal
                            }
                          }
                        }
                      ]}
                    layout={{  height: 340 }}
                />

                </div>
             
            </Skeleton>
        </div>
    )
}
