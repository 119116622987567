import React, { useState, useEffect,useContext, useRef } from "react";
import { Table, Button, Popconfirm, message, Input, Icon } from "antd";
import GlobalStateContext from "../context";
import Webservice from "../classes/WebService";


const CustomTable = (props) => {
    const { columns, dataSource,rowSelection,stateSubjectKey,bordered,config} = props;
    const [state, dispatch] = useContext(GlobalStateContext);
    const [_columns,setColumns] = useState(columns)
    const [data, setData] = useState([])
    const [selectedIds,setSelectedIds] = useState([]);
    const [searchState,setSearchState] = useState({ searchText: '',
    searchedColumn: '',});
    const [settings, setSettings] = useState({
        scrole:true
    })
    const [internalState, setInternalState] = useState( {
        data: [],
        pagination: {},
        loading: false,
      });
      const searchInput = useRef()
    
    const stateSubject = state[stateSubjectKey]; // I needed a way to make the useEffect hook refresh so i a using the subject
    const handleTableChange = (pagination, filters, sorter) => {
        console.log(filters)
        const pager = { ...internalState.pagination };
        pager.current = pagination.current;
        setInternalState({
            pagination: pager,
        });
        fetch({
            perPage: pagination.pageSize,
            page: pagination.current,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const onSearch=()=>{
        const term =  searchInput.current.value;
        console.log(term);
    }


    const defaultRowSelection = {
        onChange: (rowKey,rowData) => {
            setSelectedIds(rowKey);//rowKey because we are using the id as the keys
    },

}
   const  handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
 
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });

    
  };
   const  handleReset = clearFilters => {
        clearFilters();
        setSearchState({ ...searchState, searchText: '' });
      };
   const  getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
             
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : '')}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchState.searchInput?.select());
          }
        }
     
      });

    const fetch = (params = {}) => {
        console.log(params)
        setInternalState({ loading: true });
        let webservice = new Webservice();
        webservice.get({endpoint:dataSource,data: { perPage: 10,...params,}})
        .then(data => {
            const pagination = { ...internalState.pagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = data.data.overallCount;
            setInternalState({
                loading: false,
                data: data.data.entries,
                pagination,
            });
        });
    };

    const deleteMultiple = ()=>{
        setInternalState({ loading: true });
        let webservice = new Webservice();
        webservice.post({endpoint:`${dataSource}/delete/multiple`, data:{entities:selectedIds}})
        .then(data => {
            setInternalState({
                loading: false
            });
            fetch();
            message.success("Item(s) deleted successfully");
        });
    }

    useEffect(()=>{
        fetch();  
        const trfColumns = columns.map(column =>{
            return {...column, ...getColumnSearchProps(column.dataIndex)}
        }) 
        setColumns(trfColumns);
    },
    [dataSource,stateSubject] // fetch only when datasource or state subject changes
    ) //

    useEffect(()=>{
        fetch({[searchState.searchedColumn] : searchState.searchText });
       
    },
    [searchState])
    

    return (
        <div>
{/* 
            <div className="w-full p-2 rounded">
                <input ref={searchInput} className="p-2 m-1 border" type="search" onChange={e=>console.log(e.target.value)}/>
                <button type="submit" className="p-2 border rounded bg-gray-100 hover:bg-gray-200" onClick={onSearch} >Search</button>
            </div> */}
            <div className="" hidden={selectedIds.length < 1}>
            <Popconfirm
                title="Are you sure delete all the selected items. Action cannot be undone!"
                onConfirm={deleteMultiple}
                okText="Yes, I don't care"
                cancelText="No"
            >
                <Button icon="delete" className="m-2" />
            </Popconfirm>
              
            </div>
            <Table

                columns={_columns}
                rowKey={record => record.id}
                dataSource={internalState.data}
                pagination={internalState.pagination}
                loading={internalState.loading}
                rowSelection={rowSelection ? rowSelection : defaultRowSelection}
                onChange={handleTableChange}
                bordered = {bordered}
                scroll={{x:"100vw"}}
                
            />
        </div>
    );
}



export default CustomTable